<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <Card :title="'Tambah Pemakai Bangsal'">
          <template v-slot:body>
            <div>
              <b-form>
                <!-- Input Bed -->
                <b-form-group
                  id="input-group-bed"
                  label="Pilih Bangsal:"
                  label-for="input-bed"
                >
                  <treeselect
                    v-model="form.bed_id"
                    :multiple="false"
                    placeholder="Pilih Bangsal"
                    :options="beds"
                  />
                  <small class="text-danger">{{ error.bed_id }}</small>
                </b-form-group>

                <!-- Input Patient
                <b-form-group
                  id="input-group-patient"
                  label="Pilih Pasien:"
                  label-for="input-patient"
                >
                  <treeselect
                    v-model="form.patient_id"
                    :multiple="false"
                    :options="patients"
                    required
                  />
                  <small class="text-danger">{{ error.patient_id }}</small>
                </b-form-group> -->

                 <!-- Patient Select -->
                <b-form-group
                  id="input-group-patient"
                  label="Pilih Pasien:"
                  label-for="input-patient"
                >
                  <b-form-input
                    id="input-trigger-modal-patient"
                    v-model="display.patient_name"
                    placeholder="Pilih Pasien"
                    readonly
                    @click="$bvModal.show('modal-patient')"
                  >
                  </b-form-input>
                  <small class="text-danger">{{ error.patient_id }}</small>
                </b-form-group>

                <!-- Input Alloted Time -->
                <b-form-group
                  id="input-group-alloted-time"
                  label="Waktu Masuk:"
                  label-for="input-alloted-time"
                >
                  <b-form-datepicker
                    id="input-alloted-time"
                    v-model="form.date_in"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ error.date_in }}</small>
                </b-form-group>

                <!-- Input Discharge Time -->
                <b-form-group
                  id="input-group-discharge-time"
                  label="Waktu Keluar:"
                  label-for="input-discharge-time"
                >
                  <b-form-datepicker
                    id="input-discharge-time"
                    v-model="form.date_out"
                  ></b-form-datepicker>
                  <small class="text-danger">{{ error.date_out }}</small>
                </b-form-group>

                <!-- Button -->
                <b-button
                  variant="primary"
                  @click="formOnSubmit"
                >Simpan</b-button>
                <b-button
                  class="ml-2"
                  variant="default"
                  @click="$router.push('/beds/allotment')"
                >
                  Cancel
                </b-button>
              </b-form>
            </div>
          </template>
        </Card>
      </div>
    </div>

    <Modal
      @chosenPatient="setPatient"
      @submitedPatient="setPatient"
    />

  </div>
</template>

<script>
import Modal from "@/component/general/ModalPatient.vue";
import Card from "@/view/content/Card.vue";
import Form from "@/component/beds/Form.vue";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import module from "@/core/modules/CrudModule.js";
import validation from '@/core/modules/ValidationModule.js'

export default {
  data() {
    return {
      // Form
      display: {
        patient_name: "",
      },
      form: {
        bed_id: null,
        patient_id: null,
        date_in: null,
        date_out: null,
      },
      error: {
        bed_id: "",
        patient_id: "",
        date_in: "",
        date_out: "",
      },
      // Other
      patients: "",
      beds: [],
      // display
       display: {
        patient_name: this.$route.query.patient_name ?? '',
      },
    };
  },

  components: {
    Card,
    Form,
    Modal,
  },

  methods: {
    setPatient(value) {
      this.form.patient_id = value.id;
      this.display.patient_name = value.name;
      this.$bvModal.hide("modal-patient");
    },
    async getBed() {
      let usedBed = await module.setTreeSelect("bed-select");
      let patient = await module.setTreeSelect("patient-select");
      this.beds = usedBed.data
      this.beds.unshift({ label: 'Pilih Bangsal', id: '', isDisabled: true })
      this.patients = patient.data
    },
    async formOnSubmit() {
      // Make Request
      let response = await module.submit(this.form, 'used-beds');
      // Check Response
      if (response.state == "error") {
        // Validation Error
        this.error = validation.setValidationError(
          this.error,
          response.error.errors
        );
      } else {
        // Success
        this.$root.$emit('UpdateNewAvailableBed');
        Swal.fire(response.success.title, response.success.message, "success");
        this.$router.push("/beds/allotment");

      }
    },
    
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Bangsal", route: "" },
      { title: "Tambah Pemakai" },
    ]);
    this.getBed();
  },
};
</script>

<style>
</style>